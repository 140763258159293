<template>
  <div class="BatchChangeLevelBtn">
    <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange"></el-checkbox>
    <el-button @click="openDialog" class="batch">批量修改等级</el-button>
    <el-dialog :visible.sync="dialogVisible" width="50%">
      <template slot="title">
        <span class="dialog-title">批量修改会员等级</span>
        <span class="dialog-title-tips">会员等级相同时才能批量操作,点击分销商名称可查看等级变更历史记录</span>
      </template>
      <el-form :model="vipLevel" size="small" :rules="batchChangeFormRules" ref="levelForm" label-width="100px">
        <el-form-item label="修改等级至:" prop="level">
          <el-select
            v-model="vipLevel.level"
            placeholder="请选择会员等级"
            style="width: 200px"
            @change="selectLevelChange"
          >
            <el-option v-for="item in options" :key="item.id" :label="item.label" :value="item.id"></el-option>
          </el-select>
          <p class="input-tips">产品折扣会调整至修改后会员等级</p>
        </el-form-item>
      </el-form>
      <CommonTable height="400px" :cols="cols" :infoData="tableData" :selection="false" style="margin-top: 40px">
        <slot>
          <el-table-column label="操作" width="200px" align="center">
            <template slot-scope="{ row, $index }">
              <ColorTextBtn @click="checkOpration($index)">
                {{ row.opration ? '取消选择' : '选择' }}
              </ColorTextBtn>
            </template>
          </el-table-column>
        </slot>
      </CommonTable>
      <PaginationBar
        :total="selectionData.length"
        :page="page"
        :size="size"
        @refreshTableEventFun="handlePageChange"
      ></PaginationBar>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { debounce } from '@/utils'
import { deepClone } from '@/components/avue/utils/util'
import { tableCol as cols } from './tableCol'
export default {
  name: 'BatchChangeLevelBtn',
  components: {},
  props: {
    selectionData: {
      type: Array,
      default: () => {
        return []
      }
    },
    allSelectLength: {
      type: Number
    },
    selectionAll: {
      type: Boolean
    },
    isIndeterminateData: {
      type: Boolean
    }
  },
  data() {
    return {
      dialogVisible: false,
      checkAll: false,
      isIndeterminate: false,
      selectionDatas: [],
      vipLevel: {
        level: ''
      },
      options: [
        {
          id: '1',
          label: '黄金糕'
        },
        {
          id: '2',
          label: '双皮奶'
        },
        {
          id: '3',
          label: '蚵仔煎'
        }
      ],
      batchChangeFormRules: { level: [{ required: true, message: '请选择会员等级', trigger: 'change' }] },
      cols,
      page: 1,
      size: 10
    }
  },
  computed: {
    tableData() {
      return this.selectionDatas.slice((this.page - 1) * this.size, this.page * this.size)
    }
  },
  watch: {
    allSelectLength(newVal) {
      console.log('newVal1', newVal)
      if (!newVal) {
        this.checkAll = false
      } else {
        this.checkAll = true
      }
    },
    selectionAll(newVal) {
      this.checkAll = newVal
    },
    isIndeterminateData(newVal) {
      this.isIndeterminate = newVal
    },
    selectionData: {
      handler(newVal) {
        const newListData = deepClone(newVal)
        newListData.map((item) => {
          item.opration = true
        })
        this.selectionDatas = newListData
        // console.log('this.selectionDatas', this.selectionDatas)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    openDialog: debounce(function () {
      if (!this.selectionData.length) {
        this.$message.warning('请先选择分销商')
        return
      }
      this.getVIPLevelList()
      this.dialogVisible = true
    }, 300),
    dialogClick() {
      this.$refs['levelForm'].validate((valid) => {
        if (valid) {
          this.changeVIPLevel()
          this.dialogVisible = false
        } else {
          return false
        }
      })
    },
    handleCheckAllChange(val) {
      this.isIndeterminate = false
      this.$emit('handelCheckAllChange', val)
    },
    selectLevelChange(val) {
      console.log('val', val)
    },
    checkOpration($index) {
      const index = $index + (this.page - 1) * this.size
      this.selectionDatas[index].opration = !this.selectionDatas[index].opration
      // console.log(this.selectionDatas[$index])
    },
    handlePageChange({ page, size }) {
      this.size = size
      this.page = page
    },
    changeVIPLevel() {
      const changeDatas = this.selectionDatas.filter((item) => {
        return item.opration === true
      })
      if (!changeDatas.length) {
        this.$message.warning('请先选择修改的用户')
        return
      }
      // console.log('changeDatas', changeDatas)
      this.$message.success('成功修改会员等级')
    },
    // 获取vip等级列表
    getVIPLevelList() {}
  }
}
</script>

<style scoped lang="scss">
.batch.el-button {
  width: 112px;
  height: 32px;
  background-color: #f5f5f5;
  border: 1px solid #dcdee0;
  border-radius: 2px;
  line-height: 32px;
  padding: 0;
  color: #969799;
}
.el-checkbox {
  margin-right: 20px;
  margin-left: 10px;
}
.dialog-title-tips {
  font-size: 12px;
  color: #595961;
}
.dialog-title {
  margin-right: 10px;
  color: #1a1a1a;
}
::v-deep .el-dialog__header {
  border-bottom: 1px solid #ccc;
}
::v-deep .el-form-item__error {
  top: 150%;
}
.input-tips {
  margin: 3px 0 0 0;
  font-size: 12px;
  line-height: 12px;
  color: #595961;
}
</style>
